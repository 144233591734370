import React from "react";
import { IActivity, LanguageEnum } from "@wne/mpng-api-service";
import { UxModal, UxSizeExtraEnum } from "@wne/ux-component-library";
import { EducatorContentReader } from "@wne/shared-component-library";

import "./lesson-viewer.scss";

interface ILessonViewerModalProps {
  showModal: boolean;
  lessonTitle: string;
  doolittleBaseUrl: string;
  apiBaseUrl: string;
  activities: IActivity[];
  selectedIndex: number;
  lessonAttemptLanguage: LanguageEnum;
  spanishAvailable: boolean;
  handleClose: () => void;
  ilpId: string;
  pathId: number;
  pathVersion: number;
  title: string;
  spanishTitle?: string;
  handleGetActivities: (
    ilpId: string,
    pathId: number,
    pathVersion: number,
    title: string,
    language: LanguageEnum,
    spanishTitle?: string
  ) => void;
}

const LessonViewerModal = ({
  showModal,
  lessonTitle,
  doolittleBaseUrl,
  apiBaseUrl,
  activities,
  selectedIndex,
  lessonAttemptLanguage,
  spanishAvailable,
  handleClose,
  ilpId,
  pathId,
  pathVersion,
  title,
  spanishTitle,
  handleGetActivities
}: ILessonViewerModalProps) => (
  <UxModal
    size={UxSizeExtraEnum.FULL}
    showModal={showModal}
    showClose={true}
    handleClose={handleClose}
    title={lessonTitle}
    backdrop="static"
    scrollable={true}
    className="lesson-viewer-modal"
    body={
      <EducatorContentReader
        doolittleBaseMediaUrl={doolittleBaseUrl}
        doolittleBaseApiUrl={apiBaseUrl}
        activities={activities}
        selectedIndex={selectedIndex}
        lessonAttemptLanguage={lessonAttemptLanguage}
        spanishAvailable={spanishAvailable}
        ilpId={ilpId}
        pathId={pathId}
        pathVersion={pathVersion}
        title={title}
        spanishTitle={spanishTitle}
        handleGetActivities={handleGetActivities}
      />
    }
  />
);

export default LessonViewerModal;
