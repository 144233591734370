"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var graphqlClient_service_1 = require("./graphqlClient.service");
var graphQlClient = new graphqlClient_service_1.default();
var UsageReportDataService = {
    getStudentUsageDetails: function (startDate, endDate, classId, studentId, subjects) {
        var query = "\n            query \n                myQuery(\n                    $startTimeIso: String!, \n                    $endTimeIso: String!, \n                    $classId: String!\n                    $studentId: String!,\n                    $subjects: [String]!) {\n                        usageReport {\n                            getStudentUsageDetails(\n                                timeRange: {startTimeIso: $startTimeIso, endTimeIso: $endTimeIso},\n                                classId: $classId,\n                                studentId: $studentId,\n                                subjects: $subjects) {\n                                    pathId\n                                    parentPathId\n                                    dateTime\n                                    lessonName\n                                    activityName\n                                    activeTimeMillis\n                                    inProgress\n                                    lessonAttemptId\n                                    scorePercent\n                                    takeId\n                                    subject\n                                }\n                        }\n                    }";
        return graphQlClient
            .request(query, {
            startTimeIso: startDate,
            endTimeIso: endDate,
            classId: classId,
            studentId: studentId,
            subjects: subjects
        })
            .then(function (response) { return response.data.usageReport.getStudentUsageDetails; });
    }
};
exports.default = UsageReportDataService;
