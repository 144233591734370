"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ServiceStatusEnum;
(function (ServiceStatusEnum) {
    ServiceStatusEnum["INIT"] = "init";
    ServiceStatusEnum["LOADING"] = "loading";
    ServiceStatusEnum["LOADED"] = "loaded";
    ServiceStatusEnum["ERROR"] = "error";
})(ServiceStatusEnum || (ServiceStatusEnum = {}));
exports.default = ServiceStatusEnum;
