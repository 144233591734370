"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MapActivity = exports.MapActivities = void 0;
var MapActivities = function (activities) { return activities.map(function (activity) { return exports.MapActivity(activity); }); };
exports.MapActivities = MapActivities;
var MapActivity = function (activity) { return ({
    takeId: activity.takeID,
    pathId: activity.pathID,
    pathVersion: activity.pathVersion,
    isMasteryCheck: activity.isMasteryCheck,
    title: activity.title,
    isCompleted: activity.isCompleted,
    type: activity.type,
    score: activity.score,
    lastAttemptedOn: activity.lastAttemptedOn,
    lessonAttemptId: activity.lessonAttemptID,
    ilpId: activity.ilpID,
    timeSpentInSeconds: activity.timeSpentInSeconds
}); };
exports.MapActivity = MapActivity;
