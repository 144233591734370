import React from "react";
import ReactDOM from "react-dom";
// @ts-ignore
import { BrowserRouter as Router } from "react-router-dom";
// @ts-ignore
import axe from "react-axe";

import LtiHomeContainer from "../lti-home-container/lti-home-container";
import { AppProvider } from "../../providers/app.provider";

// In future this would be set by a control on the page
// if you want to test Spanish just change this to es
// const localeProp = "en";

const axeConf = {
  rules: [
    { id: "heading-order", enabled: true },
    { id: "label-title-only", enabled: true },
    { id: "link-in-text-block", enabled: true },
    { id: "region", enabled: true },
    { id: "skip-link", enabled: true },
    { id: "help-same-as-label", enabled: true }
  ]
};

if (process.env.NODE_ENV !== "production") {
  axe(React, ReactDOM, 1000, axeConf);
}

const RootContainer = (props: any) => (
  <>
    {props.authState === "signedIn" && (
      <AppProvider>
        <Router>
          <LtiHomeContainer />
        </Router>
      </AppProvider>
    )}
  </>
);

export default RootContainer;
