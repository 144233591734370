import React from "react";

import { UxThemesEnum, ThemeProvider, setTheme } from "@wne/ux-component-library";
import RootContainer from "../root-container/root-container";
import AuthenticatorLtiContainer from "../authenticator-lti-container/authenticator-lti-container";

class AuthContainer extends React.Component<{}, { theme: UxThemesEnum }> {
  constructor(props: any) {
    super(props);

    this.state = { theme: UxThemesEnum.EDUCATOR };
    if (process.env.NODE_ENV === "production") {
      setTheme(document, this.state.theme, "static/themes/");
    } else {
      setTheme(document, this.state.theme);
    }
  }

  render() {
    return (
      <ThemeProvider value={this.state.theme}>
        <AuthenticatorLtiContainer>
          <RootContainer />
        </AuthenticatorLtiContainer>
      </ThemeProvider>
    );
  }
}

export default AuthContainer;
