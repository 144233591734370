"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSubjectDisplayName = void 0;
var getSubjectDisplayName = function (name) {
    var lowerName = name.toLowerCase();
    if (lowerName.indexOf("ela") > -1 || lowerName.indexOf("english") > -1) {
        return "Reading";
    }
    return name;
};
exports.getSubjectDisplayName = getSubjectDisplayName;
