"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UsersMapper = exports.UserMapper = void 0;
var UserMapper = function (rawUser) {
    var user = {
        id: rawUser.id,
        firstname: rawUser.givenName,
        middlename: rawUser.middleName,
        lastname: rawUser.surname,
        email: rawUser.email,
        avatar: rawUser.avatar,
        background: rawUser.background,
        username: rawUser.username,
        fullname: rawUser.givenName + " " + rawUser.surname,
        role: rawUser.role,
        givenname: rawUser.givenName,
        tenantid: rawUser.tenantID,
        surname: rawUser.surname,
        enabled: rawUser.enabled,
        gradeLevel: rawUser.gradeLevel,
        textToSpeech: rawUser.textToSpeech,
        languageTranslation: rawUser.languageTranslation,
        isSpanishMathEnabled: rawUser.isSpanishMathEnabled
    };
    return user;
};
exports.UserMapper = UserMapper;
var UsersMapper = function (rawUsers) {
    var users = rawUsers.map(function (user) { return exports.UserMapper(user); });
    return users;
};
exports.UsersMapper = UsersMapper;
