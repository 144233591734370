"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MapIlpWithLessons = exports.MapIlps = void 0;
var getSubjectDisplayName_1 = require("../helpers/getSubjectDisplayName");
var lesson_mapper_1 = require("./lesson.mapper");
var MapIlps = function (ilp) { return ilp.map(function (ilp) { return MapIlp(ilp); }); };
exports.MapIlps = MapIlps;
var MapIlpWithLessons = function (ilp, assignedOnly) {
    var lessons = lesson_mapper_1.MapLessons(ilp.lessons);
    return __assign(__assign({}, MapIlp(ilp)), { lessons: lessons, assignedLessons: assignedOnly ? lessons : lessons.filter(function (lesson) { return lesson.isAssigned; }) });
};
exports.MapIlpWithLessons = MapIlpWithLessons;
var MapIlp = function (ilp) {
    var subjectTitle = getSubjectDisplayName_1.getSubjectDisplayName(ilp.subject);
    return {
        id: ilp.id,
        userId: ilp.studentID,
        gradeLevel: ilp.gradeLevel,
        placementGradeLevel: ilp.placementGradeLevel,
        subject: ilp.subject,
        enabled: ilp.isEnabled,
        standardCode: ilp.standardCode,
        source: ilp.source,
        createdOn: ilp.createdOn,
        subjectTitle: subjectTitle,
        productTitle: "" + subjectTitle,
        state: ilp.state,
        percentageComplete: ilp.percentageComplete,
        schoolYear: ilp.schoolYear,
        academicSessionId: ilp.academicSessionId
    };
};
