"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ky_config_1 = require("../config/ky.config");
var service_status_enum_1 = require("../enums/service-status.enum");
var getDefaultParams_1 = require("../helpers/getDefaultParams");
var session_mapper_1 = require("../mappers/session.mapper");
var SessionService = {
    createSession: function (forceSessionToken) {
        if (forceSessionToken === void 0) { forceSessionToken = ""; }
        var params = getDefaultParams_1.getDefaultParams();
        return ky_config_1.post(params.baseUrl + "Application/Session" + params.defaultParams + (forceSessionToken ? "&token=" + forceSessionToken : ""))
            .then(function (response) { return response.json(); })
            .then(function (response) {
            return formatStateResponse(service_status_enum_1.default.LOADED, session_mapper_1.default(response));
        })
            .catch(function (error) { return formatStateResponse(service_status_enum_1.default.ERROR, error); });
    },
    createHeartbeat: function () {
        var params = getDefaultParams_1.getDefaultParams();
        return ky_config_1.post(params.baseUrl + "Application/Heartbeat" + params.defaultParams)
            .then(function (response) {
            if (response.status === 203) {
                return response
                    .json()
                    .then(function (body) { return formatStateResponse(service_status_enum_1.default.ERROR, JSON.stringify(body)); });
            }
            return formatStateResponse(service_status_enum_1.default.LOADED, "");
        })
            .catch(function (error) { return formatStateResponse(service_status_enum_1.default.LOADED, error); });
    },
    createActiveEvent: function () {
        var params = getDefaultParams_1.getDefaultParams();
        return ky_config_1.post(params.baseUrl + "Application/Active" + params.defaultParams)
            .then(function () { return formatResponse(service_status_enum_1.default.LOADED); })
            .catch(function () { return formatResponse(service_status_enum_1.default.ERROR); });
    },
    createIdleEvent: function () {
        var params = getDefaultParams_1.getDefaultParams();
        return ky_config_1.post(params.baseUrl + "Application/Idle" + params.defaultParams)
            .then(function () { return formatResponse(service_status_enum_1.default.LOADED); })
            .catch(function () { return formatResponse(service_status_enum_1.default.ERROR); });
    },
    logoff: function () {
        var params = getDefaultParams_1.getDefaultParams();
        return ky_config_1.post(params.baseUrl + "Application/logoff" + params.defaultParams)
            .then(function () { return formatResponse(service_status_enum_1.default.LOADED); })
            .catch(function () { return formatResponse(service_status_enum_1.default.ERROR); });
    }
};
exports.default = SessionService;
var formatResponse = function (status) { return ({ status: status }); };
var formatStateResponse = function (status, response) { return ({ status: status, response: response }); };
