import React from "react";
import { ILessonResourceUrl } from "@wne/mpng-api-service";
import IDoolittleMetadata from "@wne/mpng-api-service/dist/interfaces/doolittle-metadata";
import LanguageTag from "../language-tag/language-tag";
import "./lesson-resource-url.scss";

interface ILessonResourceUrlProps {
  lessonResource: ILessonResourceUrl;
}

const LessonResourceUrl = (props: ILessonResourceUrlProps) => {
  const { lessonResource } = props;
  const language = lessonResource.metadata.find((t: IDoolittleMetadata) => t.label === "Language")?.value;
  const abbrevLanguage = language?.substr(0, 3).toUpperCase();

  return (
    <p>
      <a
        href={lessonResource.src}
        title={lessonResource.description}
        className="resource h5 text-gray-dark font-weight-medium"
        target="_blank"
        rel="noopener noreferrer"
      >
        {lessonResource.title}
      </a>
      {language && language.toUpperCase() === "SPANISH" && (
        <LanguageTag abbrevLanguage={abbrevLanguage || ""} fullLanguage={language} />
      )}
    </p>
  );
};

export default LessonResourceUrl;
