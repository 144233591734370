import { IUser, IUserState, ServiceStatusEnum, UserService } from "@wne/mpng-api-service";
import LogRocket from "logrocket";
import React, { createContext, useEffect, useState } from "react";

const defaultUserState: IUserState<IUser> = {
  status: ServiceStatusEnum.LOADING,
  response: ""
};

export const AppContext = createContext({
  userState: defaultUserState
});

export const AppProvider = (props: any) => {
  const [userState, setUserState] = useState(defaultUserState);

  useEffect(() => {
    const fetchData = async () => {
      const ltiToken: any = sessionStorage.getItem("ltiToken");
      if (!ltiToken || !ltiToken.length) return;
      const payload = JSON.parse(atob(ltiToken.split(".")[1]));
      const result = await UserService.getUserById(payload.UserID);
      const sendToLogRocket = sessionStorage.getItem("FF_logrocket_educator") === "on";
      setUserState(result);

      const schoolId = payload.schoolInfo?.id;
      // @ts-ignore
      if (window.pendo && window.pendo.initialize) {
        // @ts-ignore
        window.pendo.initialize({
          visitor: {
            id: payload.UserID
          },
          account: {
            id: schoolId
          }
        });
      }
      if (sendToLogRocket) {
        LogRocket.identify(payload.UserID, {
          subject: payload.launch,
          classId: payload.classId,
          schoolId,
          customerId: payload.customerId
        });
      }
    };

    fetchData();
  }, []);

  const value = {
    userState
  };

  return <AppContext.Provider value={value} {...props} />;
};
