import React from "react";
import { useTranslation } from "react-i18next";
import { IStandardCode } from "@wne/mpng-api-service";

interface IStandardCodeListProps {
  standardCodeList: IStandardCode[];
}

const StandardCodeList = (props: IStandardCodeListProps) => {
  const { standardCodeList } = props;
  const { t } = useTranslation();

  return (
    <div>
      {standardCodeList && standardCodeList.length ? (
        standardCodeList.map((standardCode: any) => (
          <div className="pt-3" key={standardCode.code}>
            <div className="h5 font-weight-bold w-100">{standardCode.code}</div>
            <div className="h5 font-weight-medium pt-3 w-100">{standardCode.description}</div>
          </div>
        ))
      ) : (
        <div className="pt-3">
          <div className="h5 font-weight-medium pt-3 w-100">{t("Standard alignment coming soon!")}</div>
        </div>
      )}
    </div>
  );
};

export default StandardCodeList;
