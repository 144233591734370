"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var RewardProgressMapper = function (rawRewardProgress) {
    var studentRewardProgress = {
        currentLevel: rawRewardProgress.currentLevel,
        currentPoints: rawRewardProgress.currentPoints,
        lastCelebratedLevel: rawRewardProgress.lastCelebratedLevel,
        lastCelebratedPoints: rawRewardProgress.lastCelebratedPoints,
        levels: rawRewardProgress.levels,
        schoolYear: rawRewardProgress.schoolYear
    };
    return studentRewardProgress;
};
exports.default = RewardProgressMapper;
