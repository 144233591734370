"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var client_1 = require("@apollo/client");
var context_1 = require("@apollo/client/link/context");
var getDefaultParams_1 = require("../helpers/getDefaultParams");
var url = getDefaultParams_1.getDefaultParams().radFastLaneUrl;
var httpLink = client_1.createHttpLink({
    uri: url
});
var authLink = context_1.setContext(function (_, _a) {
    var headers = _a.headers;
    var token = sessionStorage.getItem("ltiToken");
    return {
        headers: __assign(__assign({}, headers), { authorization: token ? "Bearer " + token : "" })
    };
});
var client = new client_1.ApolloClient({
    link: authLink.concat(httpLink),
    cache: new client_1.InMemoryCache()
});
var GraphQLClient = /** @class */ (function () {
    function GraphQLClient() {
    }
    GraphQLClient.prototype.request = function (query, variables) {
        return client.query({
            query: client_1.gql(query),
            variables: variables
        });
    };
    return GraphQLClient;
}());
exports.default = GraphQLClient;
