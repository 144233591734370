"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ky_config_1 = require("../config/ky.config");
var service_status_enum_1 = require("../enums/service-status.enum");
var getDefaultParams_1 = require("../helpers/getDefaultParams");
var get_token_1 = require("./get-token");
var session_class_1 = require("./session-class");
var appendParam = function (name, value, query) {
    if (!value)
        return query;
    var result = query ? query + "&" : "?";
    return "" + result + name + "=" + value;
};
function getPayloadFromToken() {
    var ltiToken = get_token_1.default();
    if (ltiToken) {
        var base64Encoded = ltiToken.split(".")[1];
        var tokenJson = Buffer.from(base64Encoded, "base64").toString("binary");
        return JSON.parse(tokenJson);
    }
    return "";
}
var RadService = {
    abortController: ky_config_1.abortController,
    getClassSummary: function (subjectId, startDate, endDate, timeFrameId) {
        var params = getDefaultParams_1.getDefaultParams();
        var sessionClassId = session_class_1.default.getId();
        var tokenPayload = getPayloadFromToken();
        var query = appendParam("subjectId", subjectId !== null && subjectId !== void 0 ? subjectId : getLaunchSubjectId(tokenPayload, sessionClassId), params.defaultParams);
        query = appendParam("startDate", startDate ? "" + startDate : undefined, query);
        query = appendParam("endDate", endDate ? "" + endDate : undefined, query);
        query = appendParam("timeFrameId", timeFrameId ? "" + timeFrameId : undefined, query);
        if (isSessionSubjectIdInvalid(tokenPayload, sessionClassId)) {
            clearSessionSubjectId(tokenPayload);
        }
        var requestUrl = params.baseUrl + "ClassSummary" + query;
        // TODO: get() fails but fetch() works.
        return formatResponse(fetch(requestUrl, { headers: getRequestHeaders() }));
    }
};
// check if there is a "Subject" during ClassSummary Launch
function getLaunchSubjectId(tokenPayload, sessionClassId) {
    var _a;
    var sessionSubjectId = session_class_1.default.getSubjectId();
    if (sessionSubjectId && tokenPayload.classId === sessionClassId) {
        return sessionSubjectId;
    }
    if ((tokenPayload === null || tokenPayload === void 0 ? void 0 : tokenPayload.launch) || tokenPayload.classId !== sessionClassId) {
        return ((_a = tokenPayload.launch) === null || _a === void 0 ? void 0 : _a.toUpperCase()) === "READING" ? "ela" : tokenPayload.launch;
    }
    return "";
}
function isSessionSubjectIdInvalid(tokenPayload, sessionClassId) {
    return (tokenPayload && !sessionClassId) || sessionClassId !== tokenPayload.classId;
}
function clearSessionSubjectId(tokenPayload) {
    window.sessionStorage.setItem("sessionClassId", tokenPayload.classId);
    window.sessionStorage.setItem("sessionSubjectId", "");
}
/**
 * Obtain the list of headers necessary when making a request
 */
function getRequestHeaders() {
    var ltiToken = get_token_1.default();
    var requestHeaders = {};
    // Dont set the Authorization header if the token is not present.  A null
    // bearer token value breaks CORS for some reason.
    if (ltiToken) {
        requestHeaders.Authorization = "Bearer " + ltiToken;
    }
    return new Headers(requestHeaders);
}
/**
 * formats the response of a fetch task
 * @param task the fetch task
 */
function formatResponse(task) {
    return task
        .then(function (response) {
        if (response.status !== 200)
            throw response.statusText;
        return response.json();
    })
        .then(function (response) { return getRadServiceState(response, service_status_enum_1.default.LOADED); })
        .catch(function (error) { return getRadServiceState(error, service_status_enum_1.default.ERROR); });
    function getRadServiceState(response, status) {
        return {
            status: status,
            response: response
        };
    }
}
exports.default = RadService;
