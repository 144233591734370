import React from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";

import { ILessonMetadata } from "@wne/mpng-api-service";
import LessonResourceUrlList from "../../../components/lesson-resource-url-list/lesson-resource-url-list";
import StandardCodeList from "../../../components/standard-code-list/standard-code-list";

interface IResourcesPaneProps {
  lessonMetadata: ILessonMetadata;
}

const LessonResourcesPane = (props: IResourcesPaneProps) => {
  const { lessonMetadata } = props;
  const { t } = useTranslation();

  return (
    <div className="h-100">
      <div className="h-75 d-none d-sm-none d-md-block">
        <Row className="h-100">
          <Col className="float-left col-7 bg-light w-100 pt-3">
            <div className="h5 font-weight-bold w-100">{t("Overview")}</div>
            <div className="h5 font-weight-medium pt-3 w-100">{t(lessonMetadata.description)}</div>
            <div>
              <StandardCodeList standardCodeList={lessonMetadata.standardCodes} />
            </div>
          </Col>
          <Col className="float-right col-5">
            <LessonResourceUrlList lessonResourceUrlList={lessonMetadata.attachments} />
          </Col>
        </Row>
      </div>
      <div className="h-75 d-md-none d-lg-none d-xl-none">
        <Row className="col-12 bg-light h-50 pt-3 overflow-auto">
          <div className="h5 font-weight-bold w-100">{t("Overview")}</div>
          <div className="h5 font-weight-normal pt-3 w-100">{t(lessonMetadata.description)}</div>
          <div>
            <StandardCodeList standardCodeList={lessonMetadata.standardCodes} />
          </div>
        </Row>
        <Row className="col-12 bg-gray-light h-50 pt-3 overflow-auto">
          <LessonResourceUrlList lessonResourceUrlList={lessonMetadata.attachments} />
        </Row>
      </div>
    </div>
  );
};

export default LessonResourcesPane;
