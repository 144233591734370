"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var LessonStatusEnum;
(function (LessonStatusEnum) {
    LessonStatusEnum["PASSED"] = "Passed";
    LessonStatusEnum["NOT_PASSED"] = "NotPassed";
    LessonStatusEnum["IN_PROGRESS"] = "InProgress";
    LessonStatusEnum["NOT_STARTED"] = "NotStarted";
})(LessonStatusEnum || (LessonStatusEnum = {}));
exports.default = LessonStatusEnum;
