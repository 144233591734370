import React from "react";
import "./language-tag.scss";

interface ILanguageTagProps {
  abbrevLanguage: string;
  fullLanguage: string;
}
const LanguageTag = (props: ILanguageTagProps) => (
  <span className="language-title">
    <span className="tooltiptext bg-white">{props.fullLanguage}</span>
    {props.abbrevLanguage}
  </span>
);

export default LanguageTag;
