import React from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/pro-regular-svg-icons";

import { UxSizeExtendedEnum, UxPositionEnum, UxModal } from "@wne/ux-component-library";

const LoadingModal = (props: { showModal: boolean; showText?: boolean }) => {
  const { t } = useTranslation();

  return (
    <UxModal
      showModal={props.showModal}
      handleClose={() => {}}
      body={
        <>
          <FontAwesomeIcon
            icon={faCircleNotch}
            size={UxSizeExtendedEnum.X3}
            className="animation spinner d-flex mx-auto"
          />
          {props.showText && <label>{t("Loading...")}</label>}
        </>
      }
      backdrop={true}
      bodyClassName="text-white"
      className="text-center"
      hideBorders={true}
      hideContainer={true}
      showClose={false}
      vPosition={UxPositionEnum.CENTER}
    />
  );
};

export default LoadingModal;
