"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var SessionMapper = function (rawSession) {
    var session = {
        tenantID: rawSession.tenantID,
        applicationID: rawSession.applicationID,
        role: rawSession.role,
        forceLoginToken: rawSession.forceLoginToken,
        expirationUtc: rawSession.expirationUtc,
        sessionID: rawSession.sessionID,
        userID: rawSession.userID
    };
    return session;
};
exports.default = SessionMapper;
