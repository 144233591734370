"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var ky_config_1 = require("../config/ky.config");
var service_status_enum_1 = require("../enums/service-status.enum");
var getDefaultParams_1 = require("../helpers/getDefaultParams");
var user_mapper_1 = require("../mappers/user.mapper");
var UserService = {
    getUsers: function (orgId, filter, page) {
        if (filter === void 0) { filter = ""; }
        if (page === void 0) { page = 0; }
        var params = getDefaultParams_1.getDefaultParams();
        var filterParam = filter ? "&filter=" + filter : "";
        return ky_config_1.get(params.baseUrl + "Organization/" + orgId + "/User/Detail" + params.defaultParams + filterParam + "&orderby=givenName&page=" + page)
            .then(function (response) { return response.json(); })
            .then(function (response) {
            return formatResponse(service_status_enum_1.default.LOADED, __assign(__assign({}, response), { values: user_mapper_1.UsersMapper(response.values) }));
        })
            .catch(function (error) { return formatResponse(service_status_enum_1.default.ERROR, error); });
    },
    getUserById: function (id) {
        var params = getDefaultParams_1.getDefaultParams();
        return ky_config_1.get(params.baseUrl + "User/" + id + params.defaultParams)
            .then(function (response) { return response.json(); })
            .then(function (response) { return formatResponse(service_status_enum_1.default.LOADED, user_mapper_1.UserMapper(response)); })
            .catch(function (error) { return formatResponse(service_status_enum_1.default.ERROR, error); });
    },
    updateUser: function (id, userObj) {
        var params = getDefaultParams_1.getDefaultParams();
        return ky_config_1.put(params.baseUrl + "User/" + id + params.defaultParams, {
            json: __assign({}, userObj)
        })
            .then(function (response) { return response.json(); })
            .then(function () { return formatResponse(service_status_enum_1.default.LOADED, {}); })
            .catch(function (error) { return formatResponse(service_status_enum_1.default.ERROR, error); });
    },
    getEducatorById: function (id) {
        var params = getDefaultParams_1.getDefaultParams();
        return ky_config_1.get(params.baseUrl + "User/Educator/" + id + params.defaultParams)
            .then(function (response) { return response.json(); })
            .then(function (response) { return formatResponse(service_status_enum_1.default.LOADED, user_mapper_1.UserMapper(response)); })
            .catch(function (error) { return formatResponse(service_status_enum_1.default.ERROR, error); });
    }
};
exports.default = UserService;
var formatResponse = function (status, response) { return ({ status: status, response: response }); };
