const config = {
  // order and from where user language should be detected
  order: ["navigator", "localStorage"],

  // keys or params to lookup language from
  lookupLocalStorage: "i18nextLng",

  // cache user language on
  caches: ["localStorage"],
  excludeCacheFor: ["cimode"], // languages to not persist (cookie, localStorage)

  // optional htmlTag with lang attribute, the default is:
  htmlTag: document.documentElement,

  // only detect languages that are in the whitelist
  checkWhitelist: true
};
export default config;
