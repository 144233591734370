import {
  IActivity,
  IIlp,
  IIlpServiceState,
  ILessonMetadata,
  ILessonRequest,
  IlpService,
  LanguageEnum,
  ServiceStatusEnum
} from "@wne/mpng-api-service";
import { ClassSummaryDashboard } from "@wne/shared-component-library";
import { IMenuItemSelect } from "components/student-ilp/student-ilp.interface";
import appWindow from "helpers/app-window";
import React, { lazy, Suspense, useCallback, useEffect, useState } from "react";
// @ts-ignore
import { Route, Routes, useNavigate } from "react-router-dom";
import LessonResourcesModal from "../../components/modals/lesson-resources/lesson-resources-modal";
import LessonViewerModal from "../../components/modals/lesson-viewer/lesson-viewer-modal";
import LoadingModal from "../../components/modals/loading/loading-modal";
import PageNotFound from "../../components/page-not-found/page-not-found";

const StudentILP = lazy(
  () => import("../../components/student-ilp/student-ilp" /* webpackChunkName: "enrollment-details" */)
);

const LtiHomeContainer = () => {
  const navigate = useNavigate();
  const [loadingModal, setLoadingModal] = useState({ showModal: false, showText: true });
  const [studentIlpInfo, setStudentIlpInfo] = useState({ studentId: "", subject: "", studentName: "", classId: "" });
  const [lessonResourcesModalState, setLessonResourcesModalState] = useState({
    show: false,
    title: "",
    metadata: [] as ILessonMetadata[]
  });
  const apiBaseUrl = (appWindow() as any).EDUCATOR_ENV.REACT_APP_DOOLITTLE_API_BASE_URL;
  const doolittleBaseUrl = (appWindow() as any).EDUCATOR_ENV.REACT_APP_DOOLITTLE_READER_BASE_URL;
  const [lessonViewerModalOpen, setLessonViewerModalOpen] = useState(false);
  const [lessonViewerModalState, setLessonViewerModalState] = useState({} as IMenuItemSelect);
  const handleLessonViewerClose = () => {
    setLessonViewerModalOpen(false);
    setLessonViewerModalState({} as IMenuItemSelect);
  };

  const [studentLessonInfo, setStudentLessonInfo] = useState({
    lessonTitle: "",
    activities: [] as IActivity[],
    selectedIndex: 0
  });

  const getSchoolYear = useCallback(() => {
    const ltiToken: any = sessionStorage.getItem("ltiToken");
    if (ltiToken) {
      const payload = JSON.parse(atob(ltiToken.split(".")[1]));
      return payload?.schoolInfo?.schoolYear || undefined;
    }
    return undefined;
  }, []);

  const handleStudentClick = (studentId: string, subject: string, studentName: string, classId: string) => {
    const subjectName = subject.toLowerCase() === "reading" || subject.toLowerCase() === "ela" ? "Reading" : subject;
    setStudentIlpInfo({ studentId, subject: subjectName, studentName, classId });
  };

  const displayLessonResourcesModal = (metadata: ILessonMetadata[], title?: string) => {
    setLessonResourcesModalState({ show: true, title: title || "", metadata });
  };

  const handleIlpLessonResourcesClick = async (
    pathId: number,
    studentId: string,
    standardCode: string,
    title: string,
    state: string,
    subject: string,
    gradeLevel: number | null
  ) => {
    handleSetLoadingModal(true);
    const result: IIlpServiceState<ILessonMetadata[]> = await IlpService.getLessonMetadataByPathId(
      pathId,
      studentId,
      standardCode,
      state,
      subject,
      gradeLevel
    );
    if (result.status === ServiceStatusEnum.LOADED) {
      displayLessonResourcesModal(result.response as ILessonMetadata[], title);
    }
    handleSetLoadingModal(false);
  };

  const handleReportLessonResourcesClick = async (lessons: ILessonRequest[], state: string, subject: string) => {
    handleSetLoadingModal(true);
    const result: IIlpServiceState<ILessonMetadata[]> = await IlpService.getLessonMetadataByLessonIds({
      lessons,
      state,
      subject
    });

    if (result.status === ServiceStatusEnum.LOADED) {
      displayLessonResourcesModal(result.response as ILessonMetadata[]);
    }
    handleSetLoadingModal(false);
  };

  const handleLessonResourcesModalClose = () =>
    setLessonResourcesModalState({ show: false, title: "", metadata: [] as ILessonMetadata[] });

  const handleSetLoadingModal = (showModal: boolean) => {
    const showText = false;
    setLoadingModal({ showModal, showText });
  };

  // Get lesson details using metadata call
  const getLessonDetails = async (pathId?: number) => {
    const lessonDataResponse = await IlpService.getLessonMetadataByPathId(pathId || 0, "", "");
    if (lessonDataResponse.status === ServiceStatusEnum.LOADED) {
      const lessonData = lessonDataResponse.response[0] as ILessonMetadata; // lesson meta data with path version
      handleViewLessonClick(
        "",
        lessonData.pathID,
        lessonData.version,
        lessonData.title,
        lessonViewerModalState.language || LanguageEnum.English,
        lessonData.spanishTitle
      );
    }
  };

  const getActivities = async (
    ilpId: string,
    pathId: number,
    pathVersion: number,
    language: LanguageEnum | undefined = LanguageEnum.English
  ): Promise<IIlpServiceState<IActivity[]>> => IlpService.getLessonActivities(ilpId, pathId, pathVersion, language);

  const handleViewLessonClick = async (
    ilpId: string,
    pathId: number,
    pathVersion: number,
    title: string,
    language: LanguageEnum,
    spanishTitle?: string
  ) => {
    handleSetLoadingModal(true);
    const result = await handleGetActivities(ilpId, pathId, pathVersion, title, language, spanishTitle);
    const displayTitle = language === LanguageEnum.Spanish && spanishTitle ? spanishTitle : title;
    if (result.status === ServiceStatusEnum.LOADED) {
      const activities = result.response as IActivity[];
      const count = activities.filter((activity) => activity.lastAttemptedOn).length;
      const selectedIndex = count === 0 ? 0 : count - 1;
      displayLessonViewerModal(displayTitle, activities, selectedIndex);
    }
  };

  const handleGetActivities = async (
    ilpId: string,
    pathId: number,
    pathVersion: number,
    title: string,
    language: LanguageEnum,
    spanishTitle?: string
  ) => {
    const result = await getActivities(ilpId, pathId, pathVersion, language);
    const lessonTitle = language === LanguageEnum.Spanish && spanishTitle ? spanishTitle : title;
    const activities = result.response as IActivity[];
    setStudentLessonInfo({ lessonTitle, activities, selectedIndex: studentLessonInfo.selectedIndex || 0 });

    setLessonViewerModalState({
      pathId,
      pathVersion,
      title,
      spanishTitle,
      ilpId,
      language
    } as IMenuItemSelect);
    return result;
  };

  const displayLessonViewerModal = (lessonTitle: string, activities: IActivity[], selectedIndex: number) => {
    setStudentLessonInfo({ lessonTitle, activities, selectedIndex });
    setLessonViewerModalOpen(true);
    handleSetLoadingModal(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      const result = await IlpService.getIlps(studentIlpInfo.studentId, getSchoolYear(), studentIlpInfo.subject);
      if (result.status === ServiceStatusEnum.LOADED && result.response && result.response.length) {
        const ilp = (result.response as IIlp[])[0];
        navigate(ilp.id, {
          state: {
            id: ilp.id,
            name: ilp.subjectTitle,
            studentName: studentIlpInfo.studentName,
            subject: ilp.subject,
            gradeLevel: ilp.gradeLevel,
            state: ilp.state,
            createdOn: ilp.createdOn,
            classId: studentIlpInfo.classId
          }
        });
      }
    };

    if (studentIlpInfo.studentId) fetchData();
  }, [studentIlpInfo, navigate, getSchoolYear]);

  return (
    <>
      <Suspense fallback={<LoadingModal showModal={true} showText={true} />}>
        <Routes>
          <Route
            path=":enrollmentId"
            element={
              <StudentILP
                handleLessonResourcesClick={handleIlpLessonResourcesClick}
                handleViewLessonClick={handleViewLessonClick}
                handleGetActivities={handleGetActivities}
                displayLessonViewerModal={displayLessonViewerModal}
              />
            }
          />
          <Route
            path="/"
            element={
              <ClassSummaryDashboard
                studentClickCallback={handleStudentClick}
                lessonResorcesCallback={handleReportLessonResourcesClick}
                viewLessonCallback={getLessonDetails}
              />
            }
          />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Suspense>
      {lessonResourcesModalState.show && (
        <LessonResourcesModal
          showModal={lessonResourcesModalState.show}
          title={lessonResourcesModalState.title}
          metadata={lessonResourcesModalState.metadata}
          handleModalClose={handleLessonResourcesModalClose}
        />
      )}
      {lessonViewerModalOpen && (
        <LessonViewerModal
          showModal={lessonViewerModalOpen}
          doolittleBaseUrl={doolittleBaseUrl}
          apiBaseUrl={apiBaseUrl}
          lessonTitle={studentLessonInfo.lessonTitle}
          activities={studentLessonInfo.activities}
          selectedIndex={studentLessonInfo.selectedIndex}
          spanishAvailable={!!lessonViewerModalState.spanishTitle}
          lessonAttemptLanguage={lessonViewerModalState.language}
          handleClose={handleLessonViewerClose}
          ilpId={lessonViewerModalState.ilpId}
          pathId={lessonViewerModalState.pathId}
          pathVersion={lessonViewerModalState.pathVersion}
          title={lessonViewerModalState.title}
          spanishTitle={lessonViewerModalState.spanishTitle}
          handleGetActivities={handleGetActivities}
        />
      )}
      {loadingModal.showModal && <LoadingModal showModal={loadingModal.showModal} showText={loadingModal.showText} />}
    </>
  );
};

export default LtiHomeContainer;
