"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MapLessons = void 0;
var MapLessons = function (lessons) { return lessons.map(function (lesson) { return MapLesson(lesson); }); };
exports.MapLessons = MapLessons;
var MapLesson = function (lesson) {
    var _a, _b, _c;
    return ({
        id: lesson.lessonAttemptID,
        lessonAttemptId: lesson.lessonAttemptID,
        pathId: lesson.pathID,
        pathVersion: lesson.pathVersion,
        ilpId: lesson.ilpID,
        title: lesson.title,
        spanishTitle: lesson.spanishTitle,
        language: lesson.language,
        calculatorType: lesson.calculatorType,
        gradeLevel: lesson.placementLevel,
        isAssigned: lesson.isAssigned,
        status: lesson.status,
        score: lesson.score,
        isNext: lesson.isNext,
        complete: lesson.isCompleted,
        order: lesson.order,
        statusLastUpdatedBy: lesson.lastStatusAdjustmentCreatedBy,
        domainOrder: lesson.domainOrder,
        domainName: (_a = lesson.domain) === null || _a === void 0 ? void 0 : _a.name,
        domainCode: (_b = lesson.domain) === null || _b === void 0 ? void 0 : _b.code,
        domainKey: lesson.placementLevel + "-" + ((_c = lesson.domain) === null || _c === void 0 ? void 0 : _c.code),
        lastAttemptedOn: lesson.lastAttemptedOn,
        isReset: lesson.isReset,
        gldBand: lesson.gradeLevelDomainBand
    });
};
