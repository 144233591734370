import React from "react";

interface IProps {
  authState?: string;
  authData?: any;
}

class AuthenticatorLtiContainer extends React.Component<IProps> {
  render() {
    if (React.isValidElement(this.props.children)) {
      // set default authData to match current code...
      const authData: any = {
        signInUserSession: {
          accessToken: { payload: { "cognito:groups": [] } }
        }
      };
      const ltiToken: any = sessionStorage.getItem("ltiToken");
      if (ltiToken) {
        const payload = JSON.parse(atob(ltiToken.split(".")[1]));
        // lti token is currently sending back the membership in Groups
        if (payload.Groups.includes("Student")) {
          authData.signInUserSession.accessToken.payload["cognito:groups"].push("Student");
        }
      }
      return React.cloneElement(this.props.children, {
        authState: "signedIn",
        authData
      });
    }
    return null;
  }
}

export default AuthenticatorLtiContainer;
