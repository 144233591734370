"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDefaultParams = void 0;
var getDefaultParams = function () {
    var baseUrl = "";
    var radFastLaneUrl = "";
    var sessionIdKey = "";
    var appId = "";
    if (window.STUDENT_ENV !== undefined || window.EDUCATOR_ENV !== undefined) {
        baseUrl = window.STUDENT_ENV
            ? window.STUDENT_ENV.REACT_APP_MPNG_API_BASE_URL
            : window.EDUCATOR_ENV.REACT_APP_MPNG_API_BASE_URL;
        radFastLaneUrl = window.STUDENT_ENV
            ? window.STUDENT_ENV.REACT_APP_RAD_FASTLANE_API_URL
            : window.EDUCATOR_ENV.REACT_APP_RAD_FASTLANE_API_URL;
        sessionIdKey = window.STUDENT_ENV
            ? window.STUDENT_ENV.REACT_APP_SESSION_KEYNAME
            : window.EDUCATOR_ENV.REACT_APP_SESSION_KEYNAME || "sessionId";
        appId = window.STUDENT_ENV
            ? window.STUDENT_ENV.REACT_APP_APPLICATION_ID
            : window.EDUCATOR_ENV.REACT_APP_APPLICATION_ID;
    }
    var sessionId = sessionStorage.getItem(sessionIdKey);
    var bearerToken = sessionStorage.getItem("lastKnownBearerToken");
    var appIdParam = appId ? "?applicationId=" + appId : "";
    var sessionIdParam = sessionId ? (appIdParam ? "&" : "?") + ("sessionId=" + sessionId) : "";
    return {
        baseUrl: baseUrl,
        defaultParams: "" + appIdParam + sessionIdParam,
        bearerToken: bearerToken,
        radFastLaneUrl: radFastLaneUrl
    };
};
exports.getDefaultParams = getDefaultParams;
