"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ky_config_1 = require("../config/ky.config");
var service_status_enum_1 = require("../enums/service-status.enum");
var getDefaultParams_1 = require("../helpers/getDefaultParams");
var reward_mapper_1 = require("../mappers/reward.mapper");
var EngagementService = {
    abortController: ky_config_1.abortController,
    getRewardProgressData: function (userId, gradeband, schoolYear) {
        var params = getDefaultParams_1.getDefaultParams();
        var schoolYearParam = schoolYear ? "&schoolYear=" + schoolYear : "";
        return ky_config_1.get(params.baseUrl + "RewardProgress/ByUser/" + userId + params.defaultParams + schoolYearParam + "&gradeband=" + gradeband)
            .then(function (response) { return response.json(); })
            .then(function (response) {
            return formatResponse(service_status_enum_1.default.LOADED, reward_mapper_1.default(response));
        })
            .catch(function (error) { return formatResponse(service_status_enum_1.default.ERROR, error); });
    },
    updateSelectedReward: function (userId, rewardKey, schoolYear) {
        var params = getDefaultParams_1.getDefaultParams();
        var schoolYearParam = schoolYear ? "&schoolYear=" + schoolYear : "";
        return ky_config_1.put(params.baseUrl + "RewardProgress/PutSelectedReward" + params.defaultParams + "&userId=" + userId + "&rewardKey=" + rewardKey + schoolYearParam)
            .then(function (response) { return response.json(); })
            .then(function () { return formatResponse(service_status_enum_1.default.LOADED, ""); })
            .catch(function (error) { return formatResponse(service_status_enum_1.default.ERROR, error); });
    },
    updateCelebratedPointsAndLevel: function (userId, schoolYear) {
        var params = getDefaultParams_1.getDefaultParams();
        var schoolYearParam = schoolYear ? "&schoolYear=" + schoolYear : "";
        return ky_config_1.put(params.baseUrl + "RewardProgress/PutCelebratedPointsAndLevel" + params.defaultParams + "&userId=" + userId + schoolYearParam)
            .then(function () { return formatResponse(service_status_enum_1.default.LOADED, ""); })
            .catch(function (error) { return formatResponse(service_status_enum_1.default.ERROR, error); });
    }
};
exports.default = EngagementService;
var formatResponse = function (status, response) { return ({
    status: status,
    response: response
}); };
