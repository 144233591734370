"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var ky_config_1 = require("../config/ky.config");
var grade_level_domain_band_enum_1 = require("../enums/grade-level-domain-band.enum");
var service_status_enum_1 = require("../enums/service-status.enum");
var getDefaultParams_1 = require("../helpers/getDefaultParams");
var activity_mapper_1 = require("../mappers/activity.mapper");
var ilp_mapper_1 = require("../mappers/ilp.mapper");
var lesson_mapper_1 = require("../mappers/lesson.mapper");
var IlpService = {
    getIlps: function (studentId, schoolYear, subject) {
        if (subject === void 0) { subject = ""; }
        var params = getDefaultParams_1.getDefaultParams();
        var subjectParam = subject ? "&subject=" + subject : "";
        var schoolYearParam = schoolYear ? "&schoolYear=" + schoolYear : "";
        return ky_config_1.get(params.baseUrl + "Ilp/ByStudentId/" + studentId + params.defaultParams + schoolYearParam + subjectParam)
            .then(function (response) { return response.json(); })
            .then(function (response) { return formatIlpResponse(service_status_enum_1.default.LOADED, ilp_mapper_1.MapIlps(response)); })
            .catch(function (error) { return formatIlpResponse(service_status_enum_1.default.ERROR, error); });
    },
    getIlpIncludeAssignedLessons: function (ilpId) {
        var params = getDefaultParams_1.getDefaultParams();
        return ky_config_1.get(params.baseUrl + "Ilp/" + ilpId + "/IncludeAssignedLessons" + params.defaultParams)
            .then(function (response) { return response.json(); })
            .then(function (response) {
            return formatIlpResponse(service_status_enum_1.default.LOADED, ilp_mapper_1.MapIlpWithLessons(response, true));
        })
            .catch(function (error) { return formatIlpResponse(service_status_enum_1.default.ERROR, error); });
    },
    getIlpIncludeAllAssignableLessons: function (ilpId) {
        var params = getDefaultParams_1.getDefaultParams();
        return ky_config_1.get(params.baseUrl + "Ilp/" + ilpId + "/IncludeAllAssignableLessons" + params.defaultParams)
            .then(function (response) { return response.json(); })
            .then(function (response) {
            return formatIlpResponse(service_status_enum_1.default.LOADED, ilp_mapper_1.MapIlpWithLessons(response, false));
        })
            .catch(function (error) { return formatIlpResponse(service_status_enum_1.default.ERROR, error); });
    },
    getActivityToken: function (ilpId, pathId, pathVersion, language, gradeband, placementLevel, gradeLevelDomainBand) {
        if (placementLevel === void 0) { placementLevel = 0; }
        if (gradeLevelDomainBand === void 0) { gradeLevelDomainBand = grade_level_domain_band_enum_1.default.K_TWO; }
        var params = getDefaultParams_1.getDefaultParams();
        return ky_config_1.put(params.baseUrl + "Lesson/ActivityToken" + params.defaultParams + "&gradeband=" + gradeband, {
            json: {
                ilpId: ilpId,
                pathId: pathId,
                pathVersion: pathVersion,
                placementLevel: placementLevel,
                gradeLevelDomainBand: gradeLevelDomainBand,
                language: language
            }
        })
            .then(function (response) { return response.json(); })
            .then(function (response) { return formatTokenResponse(service_status_enum_1.default.LOADED, response); })
            .catch(function (error) { return formatTokenResponse(service_status_enum_1.default.ERROR, error); });
    },
    updateLessonStatus: function (ilpId, pathId, pathVersion, status, studentId, placementLevel, gradeLevelDomainBand) {
        if (placementLevel === void 0) { placementLevel = 0; }
        if (gradeLevelDomainBand === void 0) { gradeLevelDomainBand = grade_level_domain_band_enum_1.default.K_TWO; }
        var params = getDefaultParams_1.getDefaultParams();
        return ky_config_1.put(params.baseUrl + "Lesson/UpdateStatus" + params.defaultParams + "&status=" + status + "&studentId=" + studentId, {
            json: {
                ilpId: ilpId,
                pathId: pathId,
                pathVersion: pathVersion,
                placementLevel: placementLevel,
                gradeLevelDomainBand: gradeLevelDomainBand
            }
        })
            .then(function () { return formatIlpResponse(service_status_enum_1.default.LOADED, ""); })
            .catch(function (error) { return formatIlpResponse(service_status_enum_1.default.ERROR, error); });
    },
    updateLessonAssigned: function (ilpId, pathId, assigned, studentId) {
        var params = getDefaultParams_1.getDefaultParams();
        return ky_config_1.put(params.baseUrl + "Lesson/UpdateAssigned" + params.defaultParams + "&ilpId=" + ilpId + "&pathId=" + pathId + "&assigned=" + assigned + "&studentId=" + studentId)
            .then(function () { return formatIlpResponse(service_status_enum_1.default.LOADED, ""); })
            .catch(function (error) { return formatIlpResponse(service_status_enum_1.default.ERROR, error); });
    },
    getLessonMetadataByPathId: function (pathId, studentId, ilpStandardCode, state, subject, gradeLevel) {
        if (state === void 0) { state = ""; }
        if (subject === void 0) { subject = ""; }
        if (gradeLevel === void 0) { gradeLevel = null; }
        var params = getDefaultParams_1.getDefaultParams();
        var level = !gradeLevel && gradeLevel !== 0 ? "" : "&gradeLevel=" + gradeLevel;
        return ky_config_1.get(params.baseUrl + "Lesson/" + pathId + "/Metadata" + params.defaultParams + "&ilpStandardCode=" + ilpStandardCode + "&studentId=" + studentId + "&state=" + state + "&subject=" + subject + level)
            .then(function (response) { return response.json(); })
            .then(function (response) { return formatIlpResponse(service_status_enum_1.default.LOADED, response); })
            .catch(function (error) { return formatIlpResponse(service_status_enum_1.default.ERROR, error); });
    },
    getLessonMetadataByLessonIds: function (metadataRequest) {
        var params = getDefaultParams_1.getDefaultParams();
        return ky_config_1.post(params.baseUrl + "Lesson/Metadata" + params.defaultParams, {
            json: __assign({}, metadataRequest)
        })
            .then(function (response) { return response.json(); })
            .then(function (response) { return formatIlpResponse(service_status_enum_1.default.LOADED, response); })
            .catch(function (error) { return formatIlpResponse(service_status_enum_1.default.ERROR, error); });
    },
    getLessonActivities: function (ilpId, pathId, pathVersion, language) {
        var params = getDefaultParams_1.getDefaultParams();
        var url = params.baseUrl + "Lesson/Activities" + params.defaultParams + "&ilpId=" + ilpId + "&pathId=" + pathId + "&pathVersion=" + pathVersion + "&language=" + language;
        return ky_config_1.get(url)
            .then(function (response) { return response.json(); })
            .then(function (response) { return formatIlpResponse(service_status_enum_1.default.LOADED, activity_mapper_1.MapActivities(response)); })
            .catch(function (error) { return error; });
    },
    getActivityReviewToken: function (pathId, pathVersion, takeId, isMasteryCheck, lessonAttemptId) {
        var params = getDefaultParams_1.getDefaultParams();
        return ky_config_1.get(params.baseUrl + "Activity/ReviewToken" + params.defaultParams + "&pathId=" + pathId + "&pathVersion=" + pathVersion + "&takeId=" + takeId + "&isMasteryCheck=" + isMasteryCheck + "&lessonAttemptId=" + lessonAttemptId)
            .then(function (response) { return response.json(); })
            .then(function (response) { return formatTokenResponse(service_status_enum_1.default.LOADED, response); })
            .catch(function (error) { return formatTokenResponse(service_status_enum_1.default.ERROR, error); });
    },
    setTakeSubmitComplete: function (take, schoolYear) {
        var params = getDefaultParams_1.getDefaultParams();
        var schoolYearParam = schoolYear ? "&schoolYear=" + schoolYear : "";
        return ky_config_1.post(params.baseUrl + "Activity/TakeSubmitComplete" + params.defaultParams + schoolYearParam, {
            json: __assign({}, take)
        })
            .then(function (response) { return response.json(); })
            .then(function (response) { return formatIlpResponse(service_status_enum_1.default.LOADED, response); })
            .catch(function (error) { return error; });
    },
    setSegmentStateChange: function (segment) {
        var params = getDefaultParams_1.getDefaultParams();
        return ky_config_1.post(params.baseUrl + "Activity/SegmentStateChange" + params.defaultParams, {
            json: __assign({}, segment)
        })
            .then(function (response) { return response.json(); })
            .then(function (response) { return formatIlpResponse(service_status_enum_1.default.LOADED, response); })
            .catch(function (error) { return error; });
    },
    getSingleLesson: function (userId, pathId, version) {
        var params = getDefaultParams_1.getDefaultParams();
        return ky_config_1.get(params.baseUrl + "Ilp/UserId/" + userId + "/GetSingleLesson/" + pathId + params.defaultParams + "&version=" + version)
            .then(function (response) { return response.json(); })
            .then(function (response) {
            return formatIlpResponse(service_status_enum_1.default.LOADED, ilp_mapper_1.MapIlpWithLessons(response, false));
        })
            .catch(function (error) { return formatIlpResponse(service_status_enum_1.default.ERROR, error); });
    },
    resetLesson: function (lessonRequest) {
        var params = getDefaultParams_1.getDefaultParams();
        return ky_config_1.put(params.baseUrl + "Lesson/Reset" + params.defaultParams, { json: lessonRequest })
            .then(function () { return formatIlpResponse(service_status_enum_1.default.LOADED, ""); })
            .catch(function (error) { return formatIlpResponse(service_status_enum_1.default.ERROR, error); });
    },
    getCompletedAttempts: function (ilpId, studentId) {
        var params = getDefaultParams_1.getDefaultParams();
        var url = params.baseUrl + "Lesson/GetCompletedAttemptsByIlpId/" + ilpId + params.defaultParams + "&studentId=" + studentId;
        return ky_config_1.get(url)
            .then(function (response) { return response.json(); })
            .then(function (response) { return formatIlpResponse(service_status_enum_1.default.LOADED, lesson_mapper_1.MapLessons(response)); });
    },
    getCompletedAttemptCounts: function (studentId, startDate, endDate) {
        var params = getDefaultParams_1.getDefaultParams();
        var url = params.baseUrl + "Lesson/GetCompletedAttemptCounts/" + studentId + params.defaultParams + "&startDate=" + startDate + "&endDate=" + endDate;
        return ky_config_1.get(url)
            .then(function (response) { return response.json(); })
            .then(function (response) { return formatIlpResponse(service_status_enum_1.default.LOADED, response); });
    }
};
exports.default = IlpService;
var formatIlpResponse = function (status, response) { return ({ status: status, response: response }); };
var formatTokenResponse = function (status, payload) { return ({ status: status, payload: payload }); };
