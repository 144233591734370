import { ILessonMetadata } from "@wne/mpng-api-service";
import { UxAccordion, UxModal, UxPositionEnum, UxSizeEnum, UxSizeExtraEnum } from "@wne/ux-component-library";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./lesson-resources-modal.scss";
import LessonResourcesPane from "./lesson-resources-pane";

interface IModalProps {
  showModal: boolean;
  title: string;
  metadata: ILessonMetadata[];
  handleModalClose: () => void;
}

const LessonResourcesModal = (props: IModalProps) => {
  const { showModal, title, metadata, handleModalClose } = props;
  const { t } = useTranslation();
  const [activeKey, setActiveKey] = useState(0);

  useEffect(() => {
    const activeElement = document.getElementById(`mpng-ux-accordion-child-${activeKey}`);
    if (activeElement) activeElement.scrollIntoView();
  }, [activeKey]);

  const modalTitle = t("Lesson Resources");

  return (
    <UxModal
      showModal={showModal}
      handleClose={handleModalClose}
      title={modalTitle}
      size={UxSizeExtraEnum.XL}
      scrollable={true}
      className="lesson-resources-modal"
      body={
        <UxAccordion
          defaultKey={activeKey}
          openIndicator={true}
          indicatorSize={UxSizeEnum.MEDIUM}
          items={
            metadata.length > 0
              ? metadata.map((x) => ({
                  title: (
                    <>
                      <p className="title text-primary h3">{title || x.title}</p>
                    </>
                  ),
                  body: <LessonResourcesPane lessonMetadata={x} />,
                  toggleClassName: "font-weight-bold h5 py-2 text-gray border-bottom w-100 cursor-pointer"
                }))
              : []
          }
          callback={(e: number) => setActiveKey(e)}
        />
      }
      vPosition={UxPositionEnum.BOTTOM}
      hPosition={UxPositionEnum.RIGHT}
      backdrop="static"
    />
  );
};

export default LessonResourcesModal;
