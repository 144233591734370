import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "serviceWorker";
import LogRocket from "logrocket";
import * as Sentry from "@sentry/browser";

import "./i18next/i18n";
import AuthContainer from "./containers/auth-container/auth-container";

import "index.scss";
import AppWindow from "./helpers/app-window";

const appWindow = AppWindow() as any;
const appVersion = process.env.REACT_APP_VERSION || "unknownversion";
const sentryDSN = appWindow.EDUCATOR_ENV.REACT_APP_SENTRY_DSN || "";
const sentryEnv = appWindow.EDUCATOR_ENV.REACT_APP_SENTRY_ENV || "";
const logRocketAppId = appWindow.EDUCATOR_ENV.REACT_APP_LOGROCKET_APP_ID || "";
const sendToLogRocket = sessionStorage.getItem("FF_logrocket_educator") === "on";

// LOGGING/SUPPORT INTEGRATIONS
Sentry.init({
  dsn: sentryDSN,
  environment: sentryEnv,
  release: appVersion
});

if (sendToLogRocket) {
  LogRocket.init(logRocketAppId, {
    shouldCaptureIP: false, // Don't send IP
    network: {
      responseSanitizer: (response) => {
        response.body = ""; // Don't send responses bodies
        return response;
      }
    }
  });
  // add logrocket session to sentry exception reports
  LogRocket.getSessionURL((sessionURL) => {
    Sentry.configureScope((scope) => {
      scope.setExtra("sentrySessionURL", sessionURL);
    });
  });
}

ReactDOM.render(<AuthContainer />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
