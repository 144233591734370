"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDefaultParams = void 0;
var getDefaultParams = function () {
    var baseUrl = window.STUDENT_ENV
        ? window.STUDENT_ENV.REACT_APP_MPNG_API_BASE_URL
        : window.EDUCATOR_ENV.REACT_APP_RAD_API_BASE_URL;
    return {
        baseUrl: baseUrl,
        defaultParams: ""
    };
};
exports.getDefaultParams = getDefaultParams;
