import React from "react";
import { useTranslation } from "react-i18next";
import { ILessonResourceUrl } from "@wne/mpng-api-service";
import LessonResourceUrl from "../../components/lesson-resource-url/lesson-resource-url";

interface ILessonResourceUrlListProps {
  lessonResourceUrlList: ILessonResourceUrl[];
}

const LessonResourceUrlList = (props: ILessonResourceUrlListProps) => {
  const { lessonResourceUrlList } = props;
  const { t } = useTranslation();
  const transformText = (text: string) => text.replace(/ /g, "").toLocaleLowerCase();
  const getMetadataValue = (label: string, list: ILessonResourceUrl) => {
    const element = list.metadata.find((item: any) => transformText(item.label) === transformText(label));
    return element ? element.value : "";
  };

  const worksheets = [] as ILessonResourceUrl[];
  const guidedNotes = [] as ILessonResourceUrl[];

  lessonResourceUrlList.forEach((value) => {
    const metaDataValue = getMetadataValue("resourcetype", value);
    if (transformText(metaDataValue) === transformText("Guided Notes")) {
      guidedNotes.push(value);
    } else {
      worksheets.push(value);
    }
  });

  const worksheetsTitle = t("Worksheets");
  const guidedNotesTitle = t("Guided Notes");

  const layout = (title: string, list: ILessonResourceUrl[]) => (
    <div className="bg-gray-light p-3">
      <span className="h5 font-weight-bold">{title}</span>
      <div className="pt-3">
        {list.map((lessonResourceItem: ILessonResourceUrl) => (
          <LessonResourceUrl {...{ lessonResource: lessonResourceItem }} key={lessonResourceItem.title} />
        ))}
      </div>
    </div>
  );

  return (
    <>
      {worksheets.length > 0 && layout(worksheetsTitle, worksheets)}
      {guidedNotes.length > 0 && layout(guidedNotesTitle, guidedNotes)}
    </>
  );
};

export default LessonResourceUrlList;
